export const messageUI = {
  createSuccess: '登録完了しました。',
  updateSuccess: '更新完了しました。',
  update: '更新します。よろしいですか？',
  changeToken: 'このトークンを生成します。よろしいですか？',
  changeTokenSuccess: '生成完了しました。',
  applyToken: 'このトークンを承認します。よろしいですか？',
  applyTokenSuccess: '承認完了しました。',
  subForgotPassword: 'メールアドレスを入力してください。',
  desForgotPassword:
    '* ご登録されているメールアドレスにパスワード再設定のURLをお送りいたします。',
  noData: 'データはありません。',
  emailRequired: 'メールアドレスが入力されていません。',
  emailInvalid: '正しいメールアドレスを入力してください。',
  postcodeInvalid: '郵便番号が無効です。',
  finishUrlInvalid: '最終ページURLが無効です。',
  faxInvalid: 'FAXが無効です。',
  addressRequired: '住所が入力されていません。',
  passwordRequired: 'パスワードが入力されていません。',
  oldPasswordRequired: '古いパスワードが入力されていません。',
  newPasswordRequired: '新しいパスワードが入力されていません。',
  confirmNewPasswordRequired: '新しいパスワード（確認）が入力されていません。',
  passwordMin: 'パスワードは10文字以上で入力してください',
  passwordMax: 'パスワードは100文字以内で入力してください',
  newPasswordMin: '新しいパスワードは10文字以上で入力してください',
  newPasswordMax: '新しいパスワードは100文字以内で入力してください',
  passwordInvalid:
    'パスワードは半角の大文字、小文字、数字、記号を少なくとも1つずつ含む必要があります。',
  passwordDoNotMatch: 'パスワードが一致しません。',
  required: '必須',
  placeholderInput: '入力してください。',
  placeholderSelect: '選択してください。',
  fullNameRequired: '氏名が入力されていません。',
  fullNameInvalid: '氏名が無効です。',
  phoneInvalid: '電話番号を0から始まり、10～11数字で入力してください。',
  updateProfileSuccess: 'プロファイルが正常に更新されました。',
  changePasswordSuccess: 'パスワードが正常に変更されました。',
  companyRequired: '会社名が選択されていません。',
  companyNameRequired: '会社名が入力されていません。',
  finishUrlRequired: '最終ページURLを入力してください。',
  startDateRequired: '開始日を選択してください。',
  endDateRequired: '有効期限を選択してください。',
  startDateInvalid: '開始日には有効期限より前の日付を選択してください。',
  expirationInvalid: '有効期限は開始日より後の日付を選択してください。',
  expirationIsFuture: '有効期限は将来の日付である必要があります。',
  copySuccess: 'コピーしました。',
};
