import { KeyOutlined, MenuOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Layout, MenuProps, Typography } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { deleteAllCookies, getCookie, KeyCookie } from '@/utils/storage';
import { ROLE_KEY } from '@/const/option';
import { commonUI } from '@/utils/text/UI';
import { actions, useStore } from '@/store';

type MenuItem = Required<MenuProps>['items'][number];

interface IProps {
  setOpenDraw: React.Dispatch<React.SetStateAction<boolean>>;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: React.FC<IProps> = ({ setOpenDraw, setCollapsed }) => {
  const [state, dispatch] = useStore();
  const { name } = state;
  const navigate = useNavigate();

  const headerStyle = {
    borderBlockEnd: '1px solid rgba(5, 5, 5, 0.06)',
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    height: '56px',
    backdropFilter: 'blur(8px)',
    transition: 'background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
  };
  const role = getCookie(KeyCookie.role)
    ? JSON.parse(getCookie(KeyCookie.role))
    : '';

  const logOutSuccess = () => {
    // Xóa cookies và cập nhật state
    deleteAllCookies();
    dispatch(actions.setIsAuthenticated(false));
  };

  const items: MenuItem[] =
    role !== ROLE_KEY.ADMIN
      ? [
          {
            key: '1',
            icon: <UserOutlined />,
            label: <Link to={'/user-info'}>{commonUI.settingInfo}</Link>,
          },
          {
            key: '2',
            icon: <KeyOutlined />,
            label: (
              <Link to={'/change-password'}>{commonUI.changePassword}</Link>
            ),
          },
          {
            key: '3',
            icon: <LogoutOutlined />,
            label: commonUI.logout,
            onClick: logOutSuccess,
          },
        ]
      : [
          {
            key: '1',
            icon: <KeyOutlined />,
            label: (
              <Link to={'/change-password'}>{commonUI.changePassword}</Link>
            ),
          },
          {
            key: '2',
            icon: <LogoutOutlined />,
            label: commonUI.logout,
            onClick: logOutSuccess,
          },
        ];

  return (
    <Layout.Header
      className='flex justify-between px-[10px] no-select'
      style={headerStyle}
    >
      <div
        className='flex items-center gap-[8px] cursor-pointer'
        onClick={() => navigate('/')}
      >
        <div className='px-[6px] h-full block lg:hidden'>
          <MenuOutlined
            style={{ fontSize: '18px' }}
            onClick={() => {
              setOpenDraw((prev) => !prev);
              setCollapsed(false);
            }}
          />
        </div>
        <div className='w-[40px]'>
          <img src='/logo.svg' alt='' />
        </div>
        <span className='text-[20px] font-semibold hidden lg:block'>
          BRAINWATCH
        </span>
      </div>
      <Dropdown
        className='mx-4 flex items-center cursor-pointer'
        menu={{ items }}
        placement='bottom'
        arrow={false}
        trigger={['click']}
      >
        <div className='flex gap-[8px]'>
          <Avatar src='/images/avatar.png' />
          <Typography.Text type='secondary' className='truncate max-w-[150px]'>
            {name}
          </Typography.Text>
        </div>
      </Dropdown>
    </Layout.Header>
  );
};

export default Header;
