export const ENDPOINT = {
  EXAMPLE: {
    GET_LIST: '/example',
  },
  USER: {
    PROFILE: 'profile',
    CODE_VERIFY: 'code-verify',
  },
  CONTRACT: {
    GET_LIST: '/contract',
    GET_DETAIL: '/contract/:id',
    CREATE: '/contract',
    UPDATE: '/contract/:id',
    CHANGE_TOKEN: '/contract/:id/change-token',
    APPLY_TOKEN: '/contract/:id/apply-token',
  },
  COMPANY: {
    GET_LIST: '/company',
    GET_DETAIL: '/company/:id',
    CREATE: '/company',
    UPDATE: '/company/:id',
    VERIFY_AUTHENTIC: '/company/:id/verify-authentic',
  },
  TASK: {
    GET_ALL: '/tasks',
  },
  SCORE_INDEX: {
    GET_ALL: '/score-index',
  },
};
